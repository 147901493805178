const exportVariable = {}
// source: cloudpave/compound/web/web_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

import * as jspb from 'google-protobuf';;
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.cloudpave.compound.web.CompanyData', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.Empty', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.EntityId', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.FilingData', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.FilingEntryData', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.PageData', null, global);
goog.exportSymbol('proto.cloudpave.compound.web.QueryResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.PageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.PageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.PageData.displayName = 'proto.cloudpave.compound.web.PageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.CompanyData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.CompanyData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.CompanyData.displayName = 'proto.cloudpave.compound.web.CompanyData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.FilingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.FilingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.FilingData.displayName = 'proto.cloudpave.compound.web.FilingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.FilingEntryData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.FilingEntryData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.FilingEntryData.displayName = 'proto.cloudpave.compound.web.FilingEntryData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.QueryResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloudpave.compound.web.QueryResult.repeatedFields_, null);
};
goog.inherits(proto.cloudpave.compound.web.QueryResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.QueryResult.displayName = 'proto.cloudpave.compound.web.QueryResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.EntityId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.EntityId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.EntityId.displayName = 'proto.cloudpave.compound.web.EntityId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloudpave.compound.web.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloudpave.compound.web.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloudpave.compound.web.Empty.displayName = 'proto.cloudpave.compound.web.Empty';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.PageData.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.PageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.PageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.PageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceAccessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentCompanyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    grpcEndpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.PageData}
 */
proto.cloudpave.compound.web.PageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.PageData;
  return proto.cloudpave.compound.web.PageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.PageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.PageData}
 */
proto.cloudpave.compound.web.PageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceAccessToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrpcEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.PageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.PageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.PageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.PageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGrpcEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string service_access_token = 1;
 * @return {string}
 */
proto.cloudpave.compound.web.PageData.prototype.getServiceAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.PageData} returns this
 */
proto.cloudpave.compound.web.PageData.prototype.setServiceAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 current_company_id = 2;
 * @return {number}
 */
proto.cloudpave.compound.web.PageData.prototype.getCurrentCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.PageData} returns this
 */
proto.cloudpave.compound.web.PageData.prototype.setCurrentCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string grpc_endpoint = 3;
 * @return {string}
 */
proto.cloudpave.compound.web.PageData.prototype.getGrpcEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.PageData} returns this
 */
proto.cloudpave.compound.web.PageData.prototype.setGrpcEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.CompanyData.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.CompanyData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.CompanyData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.CompanyData.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    street1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stateOrCountry: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.CompanyData}
 */
proto.cloudpave.compound.web.CompanyData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.CompanyData;
  return proto.cloudpave.compound.web.CompanyData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.CompanyData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.CompanyData}
 */
proto.cloudpave.compound.web.CompanyData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateOrCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.CompanyData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.CompanyData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.CompanyData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.CompanyData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreet1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStateOrCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 company_id = 1;
 * @return {number}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street1 = 3;
 * @return {string}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getStreet1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setStreet1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state_or_country = 5;
 * @return {string}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getStateOrCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setStateOrCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zip_code = 6;
 * @return {string}
 */
proto.cloudpave.compound.web.CompanyData.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.CompanyData} returns this
 */
proto.cloudpave.compound.web.CompanyData.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.FilingData.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.FilingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.FilingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.FilingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    filingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    periodOfReport: jspb.Message.getFieldWithDefault(msg, 2, ""),
    submissionType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.FilingData}
 */
proto.cloudpave.compound.web.FilingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.FilingData;
  return proto.cloudpave.compound.web.FilingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.FilingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.FilingData}
 */
proto.cloudpave.compound.web.FilingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFilingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodOfReport(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmissionType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.FilingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.FilingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.FilingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.FilingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPeriodOfReport();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubmissionType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 filing_id = 1;
 * @return {number}
 */
proto.cloudpave.compound.web.FilingData.prototype.getFilingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.FilingData} returns this
 */
proto.cloudpave.compound.web.FilingData.prototype.setFilingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string period_of_report = 2;
 * @return {string}
 */
proto.cloudpave.compound.web.FilingData.prototype.getPeriodOfReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.FilingData} returns this
 */
proto.cloudpave.compound.web.FilingData.prototype.setPeriodOfReport = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string submission_type = 3;
 * @return {string}
 */
proto.cloudpave.compound.web.FilingData.prototype.getSubmissionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.FilingData} returns this
 */
proto.cloudpave.compound.web.FilingData.prototype.setSubmissionType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 company_id = 4;
 * @return {number}
 */
proto.cloudpave.compound.web.FilingData.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.FilingData} returns this
 */
proto.cloudpave.compound.web.FilingData.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.FilingEntryData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.FilingEntryData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.FilingEntryData.toObject = function(includeInstance, msg) {
  var f, obj = {
    securityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    securityName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.FilingEntryData}
 */
proto.cloudpave.compound.web.FilingEntryData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.FilingEntryData;
  return proto.cloudpave.compound.web.FilingEntryData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.FilingEntryData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.FilingEntryData}
 */
proto.cloudpave.compound.web.FilingEntryData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalShares(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.FilingEntryData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.FilingEntryData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.FilingEntryData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecurityName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string security_id = 1;
 * @return {string}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.FilingEntryData} returns this
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string security_name = 2;
 * @return {string}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.getSecurityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.FilingEntryData} returns this
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.setSecurityName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 total_value = 3;
 * @return {number}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.getTotalValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.FilingEntryData} returns this
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.setTotalValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_shares = 4;
 * @return {number}
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloudpave.compound.web.FilingEntryData} returns this
 */
proto.cloudpave.compound.web.FilingEntryData.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloudpave.compound.web.QueryResult.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.QueryResult.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.QueryResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.QueryResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.QueryResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.cloudpave.compound.web.CompanyData.toObject, includeInstance),
    filingsList: jspb.Message.toObjectList(msg.getFilingsList(),
    proto.cloudpave.compound.web.FilingData.toObject, includeInstance),
    filingEntriesList: jspb.Message.toObjectList(msg.getFilingEntriesList(),
    proto.cloudpave.compound.web.FilingEntryData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.QueryResult}
 */
proto.cloudpave.compound.web.QueryResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.QueryResult;
  return proto.cloudpave.compound.web.QueryResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.QueryResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.QueryResult}
 */
proto.cloudpave.compound.web.QueryResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloudpave.compound.web.CompanyData;
      reader.readMessage(value,proto.cloudpave.compound.web.CompanyData.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    case 2:
      var value = new proto.cloudpave.compound.web.FilingData;
      reader.readMessage(value,proto.cloudpave.compound.web.FilingData.deserializeBinaryFromReader);
      msg.addFilings(value);
      break;
    case 3:
      var value = new proto.cloudpave.compound.web.FilingEntryData;
      reader.readMessage(value,proto.cloudpave.compound.web.FilingEntryData.deserializeBinaryFromReader);
      msg.addFilingEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.QueryResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.QueryResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.QueryResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.QueryResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloudpave.compound.web.CompanyData.serializeBinaryToWriter
    );
  }
  f = message.getFilingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloudpave.compound.web.FilingData.serializeBinaryToWriter
    );
  }
  f = message.getFilingEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cloudpave.compound.web.FilingEntryData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyData companies = 1;
 * @return {!Array<!proto.cloudpave.compound.web.CompanyData>}
 */
proto.cloudpave.compound.web.QueryResult.prototype.getCompaniesList = function() {
  return /** @type{!Array<!proto.cloudpave.compound.web.CompanyData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloudpave.compound.web.CompanyData, 1));
};


/**
 * @param {!Array<!proto.cloudpave.compound.web.CompanyData>} value
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
*/
proto.cloudpave.compound.web.QueryResult.prototype.setCompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloudpave.compound.web.CompanyData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloudpave.compound.web.CompanyData}
 */
proto.cloudpave.compound.web.QueryResult.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloudpave.compound.web.CompanyData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
 */
proto.cloudpave.compound.web.QueryResult.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};


/**
 * repeated FilingData filings = 2;
 * @return {!Array<!proto.cloudpave.compound.web.FilingData>}
 */
proto.cloudpave.compound.web.QueryResult.prototype.getFilingsList = function() {
  return /** @type{!Array<!proto.cloudpave.compound.web.FilingData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloudpave.compound.web.FilingData, 2));
};


/**
 * @param {!Array<!proto.cloudpave.compound.web.FilingData>} value
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
*/
proto.cloudpave.compound.web.QueryResult.prototype.setFilingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloudpave.compound.web.FilingData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloudpave.compound.web.FilingData}
 */
proto.cloudpave.compound.web.QueryResult.prototype.addFilings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloudpave.compound.web.FilingData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
 */
proto.cloudpave.compound.web.QueryResult.prototype.clearFilingsList = function() {
  return this.setFilingsList([]);
};


/**
 * repeated FilingEntryData filing_entries = 3;
 * @return {!Array<!proto.cloudpave.compound.web.FilingEntryData>}
 */
proto.cloudpave.compound.web.QueryResult.prototype.getFilingEntriesList = function() {
  return /** @type{!Array<!proto.cloudpave.compound.web.FilingEntryData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloudpave.compound.web.FilingEntryData, 3));
};


/**
 * @param {!Array<!proto.cloudpave.compound.web.FilingEntryData>} value
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
*/
proto.cloudpave.compound.web.QueryResult.prototype.setFilingEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cloudpave.compound.web.FilingEntryData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloudpave.compound.web.FilingEntryData}
 */
proto.cloudpave.compound.web.QueryResult.prototype.addFilingEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cloudpave.compound.web.FilingEntryData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloudpave.compound.web.QueryResult} returns this
 */
proto.cloudpave.compound.web.QueryResult.prototype.clearFilingEntriesList = function() {
  return this.setFilingEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.EntityId.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.EntityId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.EntityId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.EntityId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.EntityId}
 */
proto.cloudpave.compound.web.EntityId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.EntityId;
  return proto.cloudpave.compound.web.EntityId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.EntityId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.EntityId}
 */
proto.cloudpave.compound.web.EntityId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.EntityId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.EntityId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.EntityId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.EntityId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cloudpave.compound.web.EntityId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloudpave.compound.web.EntityId} returns this
 */
proto.cloudpave.compound.web.EntityId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloudpave.compound.web.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.cloudpave.compound.web.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloudpave.compound.web.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloudpave.compound.web.Empty}
 */
proto.cloudpave.compound.web.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloudpave.compound.web.Empty;
  return proto.cloudpave.compound.web.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloudpave.compound.web.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloudpave.compound.web.Empty}
 */
proto.cloudpave.compound.web.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloudpave.compound.web.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloudpave.compound.web.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloudpave.compound.web.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloudpave.compound.web.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


export const { CompanyData, Empty, EntityId, FilingData, FilingEntryData, PageData, QueryResult } = proto.cloudpave.compound.web
