const exportVariable = {}
/**
 * @fileoverview gRPC-Web generated client stub for cloudpave.compound.web
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
import * as grpc_web from 'grpc-web';
grpc.web = {...grpc_web};

const proto = {};
proto.cloudpave = {};
proto.cloudpave.compound = {};
import * as proto_cloudpave_compound_web from './web_types_pb';
proto.cloudpave.compound.web = {...proto_cloudpave_compound_web};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
exportVariable.QueryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
exportVariable.QueryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.Empty,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodDescriptor_QueryService_AllCompanies = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/AllCompanies',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.Empty,
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.Empty,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodInfo_QueryService_AllCompanies = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.QueryResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.QueryResult>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.allCompanies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/AllCompanies',
      request,
      metadata || {},
      methodDescriptor_QueryService_AllCompanies,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.QueryResult>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.allCompanies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/AllCompanies',
      request,
      metadata || {},
      methodDescriptor_QueryService_AllCompanies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.CompanyData>}
 */
const methodDescriptor_QueryService_CompanyById = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/CompanyById',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.EntityId,
  proto.cloudpave.compound.web.CompanyData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.CompanyData.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.CompanyData>}
 */
const methodInfo_QueryService_CompanyById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.CompanyData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.CompanyData.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.CompanyData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.CompanyData>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.companyById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/CompanyById',
      request,
      metadata || {},
      methodDescriptor_QueryService_CompanyById,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.CompanyData>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.companyById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/CompanyById',
      request,
      metadata || {},
      methodDescriptor_QueryService_CompanyById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodDescriptor_QueryService_FilingsByCompany = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/FilingsByCompany',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.EntityId,
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodInfo_QueryService_FilingsByCompany = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.QueryResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.QueryResult>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.filingsByCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingsByCompany',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingsByCompany,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.QueryResult>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.filingsByCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingsByCompany',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingsByCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.FilingData>}
 */
const methodDescriptor_QueryService_FilingById = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/FilingById',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.EntityId,
  proto.cloudpave.compound.web.FilingData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.FilingData.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.FilingData>}
 */
const methodInfo_QueryService_FilingById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.FilingData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.FilingData.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.FilingData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.FilingData>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.filingById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingById',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingById,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.FilingData>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.filingById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingById',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodDescriptor_QueryService_FilingEntries = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/FilingEntries',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.EntityId,
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.QueryResult>}
 */
const methodInfo_QueryService_FilingEntries = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.QueryResult,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.QueryResult.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.QueryResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.QueryResult>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.filingEntries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingEntries',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingEntries,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.QueryResult>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.filingEntries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/FilingEntries',
      request,
      metadata || {},
      methodDescriptor_QueryService_FilingEntries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.FilingData>}
 */
const methodDescriptor_QueryService_LatestFiling = new grpc.web.MethodDescriptor(
  '/cloudpave.compound.web.QueryService/LatestFiling',
  grpc.web.MethodType.UNARY,
  proto.cloudpave.compound.web.EntityId,
  proto.cloudpave.compound.web.FilingData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.FilingData.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.cloudpave.compound.web.EntityId,
 *   !proto.cloudpave.compound.web.FilingData>}
 */
const methodInfo_QueryService_LatestFiling = new grpc.web.AbstractClientBase.MethodInfo(
  proto.cloudpave.compound.web.FilingData,
  /**
   * @param {!proto.cloudpave.compound.web.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cloudpave.compound.web.FilingData.deserializeBinary
);


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.cloudpave.compound.web.FilingData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cloudpave.compound.web.FilingData>|undefined}
 *     The XHR Node Readable Stream
 */
exportVariable.QueryServiceClient.prototype.latestFiling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/LatestFiling',
      request,
      metadata || {},
      methodDescriptor_QueryService_LatestFiling,
      callback);
};


/**
 * @param {!proto.cloudpave.compound.web.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cloudpave.compound.web.FilingData>}
 *     A native promise that resolves to the response
 */
exportVariable.QueryServicePromiseClient.prototype.latestFiling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cloudpave.compound.web.QueryService/LatestFiling',
      request,
      metadata || {},
      methodDescriptor_QueryService_LatestFiling);
};


export const { QueryServiceClient, QueryServicePromiseClient } = exportVariable;

